import Base = require("Everlaw/Base");
import Database = require("Everlaw/Database");
import { LegalHold } from "Everlaw/LegalHold/LegalHold";
import { LegalHoldMatter } from "Everlaw/LegalHold/LegalHoldMatter";
import { OrganizationId } from "Everlaw/MinimalOrganization";

export type OwningEntity = Database | LegalHoldMatter;
export type OwningEntityOption = OwningEntity | AllOwningEntity;

export enum OwningEntityType {
    Database = "Database",
    Matter = "Matter",
}

export function getOwningEntityType(entity: OwningEntity): OwningEntityType {
    return entity instanceof Database ? OwningEntityType.Database : OwningEntityType.Matter;
}

/**
 * Returns whether the given name is unused by another Database or LegalHoldMatter in the same org.
 * Assumes the relevant Database and LegalHoldMatter objects have already been loaded into Base.
 */
export function isValidDbOrMatterName(name: string, orgId: OrganizationId): boolean {
    const lowerCase = name.toLowerCase();
    const unusedByDbs = Base.get(Database)
        .filter((db) => db.owningOrgId === orgId)
        .every((db) => db.name.toLowerCase() !== lowerCase);
    const unusedByMatters = Base.get(LegalHoldMatter)
        .filter((matter) => matter.orgId === orgId)
        .every((matter) => matter.name.toLowerCase() !== lowerCase);
    return unusedByDbs && unusedByMatters;
}

/**
 * Get an OwningEntity given its id. Returns undefined if no such OwningEntity exists.
 */
export function getOwningEntity(id: number): OwningEntity {
    return Base.get(Database, id) || Base.get(LegalHoldMatter, id);
}

export function getOrgIdFromEntityId(owningEntityId: number): OrganizationId {
    return (
        Base.get(Database, owningEntityId)?.owningOrgId
        ?? Base.get(LegalHoldMatter, owningEntityId).orgId
    );
}

export function getOrgIdFromEntity(owningEntity: OwningEntity): OrganizationId {
    return owningEntity instanceof Database ? owningEntity.owningOrgId : owningEntity.orgId;
}

/**
 * Returns the set of owning entities used in the given legal holds.
 */
export function getOwningEntitiesInLegalHolds(holds: LegalHold[]): Set<OwningEntity> {
    const result = new Set<OwningEntity>();
    for (const hold of holds) {
        const entity = getOwningEntity(hold.owningEntityId);
        if (entity) {
            result.add(entity);
        }
    }
    return result;
}

export class AllOwningEntity extends Base.Primitive<string> {
    constructor() {
        super("allOwningEntityID", "(All)");
    }
}
