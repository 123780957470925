import Base = require("Everlaw/Base");
import * as DatabaseFieldValue from "Everlaw/DatabaseField/DatabaseFieldValue";
import { OrganizationId } from "Everlaw/MinimalOrganization";

interface LegalHoldMatterJson extends LegalHoldMatter {
    matterFieldValues?: { [fieldId: number]: DatabaseFieldValue };
}

export class LegalHoldMatter extends Base.Object {
    override id: number;
    parcel: Parcel;
    name: string;
    orgId: OrganizationId;
    emailSuspended: boolean;

    get className(): string {
        return "LegalHoldMatter";
    }

    constructor(params: LegalHoldMatterJson) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: LegalHoldMatterJson): void {
        Object.assign(this, params);
        const matterFieldValues = params.matterFieldValues ?? {};
        Object.keys(matterFieldValues).forEach((fieldId) => {
            Base.set(DatabaseFieldValue, matterFieldValues[+fieldId]);
        });
    }

    override display(): string {
        return this.name;
    }
}
