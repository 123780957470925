import Base = require("Everlaw/Base");
import { Compare as Cmp } from "core";
import Database = require("Everlaw/Database");
import DatabaseField = require("Everlaw/DatabaseField/DatabaseField");
import DatabaseFieldValue = require("Everlaw/DatabaseField/DatabaseFieldValue");
import DateTimePrecision = require("Everlaw/DateTimePrecision");
import DateTimeWidget = require("Everlaw/UI/DateTimeWidget");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import { Is } from "core";
import MultiSelect = require("Everlaw/UI/MultiSelect");
import NumberBox = require("Everlaw/UI/NumberBox");
import Project = require("Everlaw/Project");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import TextBox = require("Everlaw/UI/TextBox");
import Tooltip = require("Everlaw/UI/Tooltip");
import Util = require("Everlaw/Util");
import { getOrgIdFromEntity, OwningEntity } from "Everlaw/LegalHold/HoldOwningEntity";
import { CJIS_ORG_EXPLANATION, Organization } from "Everlaw/Organization";
import type { DateTime } from "Everlaw/Type";

export function getEditWidgetForField(
    org: Organization,
    field: DatabaseField,
    database?: Database,
    onChange?: (value: DatabaseFieldValue.ValueJson) => void,
): EditWidget {
    let body: Dom.Nodeable = null;
    let destroyable: Util.Destroyable = null;
    let getValue: () => DatabaseFieldValue.ValueJson = null;
    let hasValue: () => boolean = null;
    const rawValue = database?.databaseFieldValues[field.id];
    const rawValueJson = rawValue?.json;
    if (field.type === DatabaseField.Type.TEXT) {
        const tb = new TextBox({
            placeholder: "Enter value",
        });
        tb.onChange = (value) => {
            onChange && onChange(value);
        };
        rawValueJson && tb.setValue(<string>rawValueJson, true);
        body = tb;
        destroyable = tb;
        getValue = () => tb.getValue().trim();
        hasValue = () => !!tb.getValue().trim();
    } else if (field.isSingleOrMultiSelect()) {
        const rawOptions = (<DatabaseField.SelectJson>field.json).options;
        if (field.type === DatabaseField.Type.SINGLE_SELECT) {
            let defaultOption: Base.DataPrimitive<number>;
            const options = rawOptions.map((option, i) => {
                const prim = new Base.DataPrimitive(i, option.id, option.text);
                if (option.id === rawValueJson) {
                    defaultOption = prim;
                }
                return prim;
            });
            if (!field.isRequired) {
                const nullOption = new Base.DataPrimitive<number>(-1, DatabaseField.NO_VALUE, "—");
                if (!defaultOption) {
                    defaultOption = nullOption;
                }
                options.push(nullOption);
            }
            const optionsSelect = new SingleSelect<Base.DataPrimitive<number>>({
                elements: options,
                initialSelected: defaultOption,
                popup: "after",
                headers: false,
                selectOnSame: true,
                placeholder: "Select value",
                onChange: (option) => {
                    let val = Is.string(option) ? option : option.id;
                    if (onChange) {
                        if (val === DatabaseField.NO_VALUE) {
                            val = null;
                            optionsSelect.tb.setValue("", true);
                        }
                        onChange && onChange(val);
                    }
                    updateDescriptionBody(Is.string(option) ? option : option.name);
                    optionsSelect.minimize();
                    optionsSelect.blur();
                },
                clickableIcon: true,
                textBoxParams: {
                    icon: new Icon("caret-down-20"),
                },
                comparator: (a, b) => a.data - b.data,
            });
            if (isFieldCjisUnmodifiable(field, org)) {
                // Conditional message if we're creating a new DB
                const message = database
                    ? `Field value cannot be changed because ${CJIS_ORG_EXPLANATION}`
                    : `New databases are required to have this field because ${CJIS_ORG_EXPLANATION}`;
                new Tooltip(optionsSelect, message);
                if (!database) {
                    // When creating a new database, and the user can't edit this field, it should default to CJIS.
                    optionsSelect.setValue(options[0], true);
                }
                optionsSelect.setDisabled(true);
            }

            let descriptionBody: HTMLElement;
            body = Dom.div(
                optionsSelect.getNode(),
                (descriptionBody = Dom.div({ class: "database-field__description" })),
            );

            const updateDescriptionBody = (val: string) => {
                const showCjisDescription =
                    !database
                    || (field.isEverlawAccessRestriction()
                        && val === DatabaseField.EVERLAW_ACCESS_RESTRICTIONS_CJIS_VALUE);
                Dom.setContent(
                    descriptionBody,
                    showCjisDescription ? DatabaseField.EVERLAW_ACCESS_RESTRICTIONS_INFO : "",
                );
            };

            updateDescriptionBody(defaultOption?.name);

            // body is set above
            destroyable = optionsSelect;
            getValue = () => optionsSelect.getSelected()?.id;
            hasValue = () =>
                optionsSelect.getSelected()
                && optionsSelect.getSelected()?.id !== DatabaseField.NO_VALUE;
        } else if (field.type === DatabaseField.Type.MULTI_SELECT) {
            const initialSelected: Base.DataPrimitive<number>[] = [];
            const selectedIds = new Set();
            if (Is.array(rawValueJson)) {
                rawValueJson.forEach((val) => selectedIds.add(val));
            }
            const options = rawOptions.map((option, i) => {
                const prim = new Base.DataPrimitive(i, option.id, option.text);
                if (selectedIds.has(option.id)) {
                    initialSelected.push(prim);
                }
                return prim;
            });
            const updateMultiselectTextbox = () => {
                const values = <Base.DataPrimitive<number>[]>optionsMultiselect.getValue();
                optionsMultiselect.setValue(
                    values.length === 0
                        ? ""
                        : values.length === 1
                          ? values[0].display()
                          : `(${values.length} selected)`,
                );
            };
            const optionsMultiselect = new MultiSelect({
                elements: options,
                popup: "after",
                initialSelected,
                headers: false,
                placeholder: "Select value",
                onChange: () => {
                    updateMultiselectTextbox();
                    if (onChange) {
                        const values = <Base.DataPrimitive<number>[]>optionsMultiselect.getValue();
                        onChange(values.map((val) => val.id as number));
                    }
                },
                clickableIcon: true,
                textBoxParams: {
                    icon: new Icon("caret-down-20"),
                },
                comparator: (a, b) => a.data - b.data,
            });
            updateMultiselectTextbox();
            body = optionsMultiselect;
            destroyable = optionsMultiselect;
            getValue = () => {
                const values = <Base.Primitive<number>[]>optionsMultiselect.getValue();
                return values.map((val) => val.id);
            };
            hasValue = () => optionsMultiselect.getValue().length > 0;
        }
    } else if (field.type === DatabaseField.Type.NUMBER) {
        const numberBox = new NumberBox({
            placeholder: "Enter number",
            boxFormat: {
                onChange: (value) => {
                    onChange && onChange(value);
                },
            },
        });
        Is.number(rawValueJson) && numberBox.setValue(rawValueJson);
        body = numberBox;
        destroyable = numberBox;
        getValue = () => numberBox.getValue();
        hasValue = () => Is.number(numberBox.getValue());
    } else if (field.type === DatabaseField.Type.DATE) {
        const mode = DateTimePrecision.hasHourPrecision(
            (<DatabaseField.DateTimeJson>field.json).precision,
        )
            ? DateTimeWidget.Mode.BOTH
            : DateTimeWidget.Mode.DATE_ONLY;
        const dateTimeWidget = new DatabaseFieldDateTimeForm({ mode, noLabels: true });
        dateTimeWidget.setOnChange((value) => {
            onChange && onChange(value);
        });
        rawValueJson && dateTimeWidget.setValue(<DateTime>rawValueJson);
        body = dateTimeWidget;
        destroyable = dateTimeWidget;
        getValue = () => dateTimeWidget.getValue();
        hasValue = () => !!dateTimeWidget.getValue();
    }
    return (
        body && {
            body,
            destroyable,
            getValue,
            hasValue,
        }
    );
}

export interface EditWidget {
    body: Dom.Nodeable;
    destroyable: Util.Destroyable;
    getValue: () => DatabaseFieldValue.ValueJson;
    hasValue: () => boolean;
}

export class DatabaseFieldDateTimeForm extends DateTimeWidget.DateTimeForm {
    timeAndZoneContainer: HTMLElement;
    protected override initDOM(attrs) {
        this.node = Dom.div(
            { class: "database-field__date-time-edit-container" },
            (this.dateboxContainer = Dom.div({ class: "database-field__date-time-edit-datebox" })),
            (this.timeAndZoneContainer = Dom.div(
                { class: "database-field__date-time-edit-time-and-zone" },
                Dom.span("at"),
                (this.timeboxContainer = Dom.div({ class: "database-field__date-time-edit-time" })),
                (this.tzWidgetContainer = Dom.div({
                    class: "date-time-widget__timezone-container",
                })),
                (this.tzInfo = Dom.a({ class: "date-time-widget__timezone-info action" })),
            )),
        );
    }
    protected override updateInputsTable() {
        super.updateInputsTable();
        Dom.setContent(this.dateboxContainer, Dom.node(this.dateBox));
        Dom.setContent(this.timeboxContainer, Dom.node(this.timeBox));
        Dom.show(this.timeAndZoneContainer, this.modeIs(DateTimeWidget.Mode.BOTH));
    }
    protected override updateTimezonesVisibility() {
        // hide the timezone selector when the timebox is empty
        if (
            this.tzWidget
            && Project.CURRENT
            && this.tzWidget.getTimezoneId() !== Project.CURRENT.timezoneId
        ) {
            this.hasEverEditedTimezone = true;
        }
        const show = this.hasEverEditedTimezone;
        Dom.show(this.tzInfo, !show);
        Dom.show(this.tzWidgetContainer, !!show);
    }
}

/**
 * Get all pinned field values for an owning entity (database/matter).
 * If the owning entity doesn't have any pinned fields, return an empty array.
 */
export function getPinnedFieldValuesForOwningEntity(
    owningEntity: OwningEntity,
): DatabaseFieldValue[] {
    const pinnedFields = Base.get(DatabaseField).filter(
        (field) => field.organizationId === getOrgIdFromEntity(owningEntity) && field.isPinned,
    );
    if (pinnedFields.length === 0) {
        return [];
    }
    pinnedFields.sort((f1, f2) => Cmp.str(f1.rank, f2.rank));
    const pinnedFieldValueIds = pinnedFields.map((field) => `${owningEntity.id}:${field.id}`);
    return Base.get(DatabaseFieldValue, pinnedFieldValueIds);
}

/**
 * Get all pinned field values for a database.
 * If a database doesn't have pinned fields, return an empty array.
 */
export function getPinnedFieldValuesForDatabase(database: Database): DatabaseFieldValue[] {
    if (!database || !database.databaseFieldValues) {
        return [];
    }
    const fieldIds = Object.keys(database.databaseFieldValues);
    const pinnedFields = Base.get(DatabaseField, fieldIds).filter((field) => field.isPinned);
    if (pinnedFields.length === 0) {
        return [];
    }
    pinnedFields.sort((f1, f2) => Cmp.str(f1.rank, f2.rank));
    return pinnedFields.map((field) => database.databaseFieldValues[field.id]);
}

/**
 * If the database has pinned fields, returns an HTMLElement containing the pinned field displays.
 * Otherwise, returns null.
 * The React version of this function is in {DatabaseUtilReact.tsx#PinnedFieldsForDatabase}
 */
export function getPinnedFieldsHtmlElementForDatabase(database: Database): {
    node: HTMLElement;
    toDestroy: Util.Destroyable[];
} {
    const pinnedFieldValues = getPinnedFieldValuesForDatabase(database);
    if (pinnedFieldValues.length === 0) {
        return null;
    }
    return getPinnedFieldsHtmlElementForValues(pinnedFieldValues);
}

/**
 * If there are any pinned field values, return an HTMLElement with those values' displays and a
 * destroyable. Otherwise, return null.
 * The React version of this function is in {DatabaseUtilReact.tsx#PinnedFieldsForValues}
 */
export function getPinnedFieldsHtmlElementForValues(pinnedFieldValues: DatabaseFieldValue[]): {
    node: HTMLElement;
    toDestroy: Util.Destroyable[];
} {
    if (!pinnedFieldValues || pinnedFieldValues.length === 0) {
        return null;
    }
    const fieldsDiv = Dom.div({ class: "database-field-value-badge__container" });
    const fieldsFullDisplayDiv = Dom.div({ class: "h-spaced-4" }); // Used for tooltip
    const fieldShortDisplayNodes: HTMLElement[] = [];
    let anyMultiSelectFieldsTruncated = false;
    pinnedFieldValues.forEach((fieldValue, index) => {
        const shortDisplay = fieldValue.shortDisplay();
        if (!shortDisplay) {
            return;
        }
        const fieldShortDisplayNode = Dom.span(
            { class: "database-field-value-badge__display" },
            shortDisplay,
        );
        const fieldBadge = Dom.span({ class: "database-field-value-badge" }, fieldShortDisplayNode);
        if (fieldValue.getField().type === DatabaseField.Type.MULTI_SELECT) {
            const len = (<number[]>fieldValue.json).length;
            if (len > 1) {
                Dom.addContent(fieldBadge, Dom.span(`(+${len - 1})`));
                anyMultiSelectFieldsTruncated = true;
            }
        }
        fieldShortDisplayNodes.push(fieldShortDisplayNode);
        Dom.addContent(fieldsDiv, fieldBadge);
        Dom.addContent(
            fieldsFullDisplayDiv,
            Dom.span({ class: "database-field-value-badge__tooltip" }, fieldValue.display()),
        );
        if (index < pinnedFieldValues.length - 1) {
            Dom.addContent(fieldsDiv, Dom.span("•"));
            Dom.addContent(fieldsFullDisplayDiv, Dom.span(" • "));
        }
    });
    const tooltip = new (class extends Tooltip {
        override getHTMLContent(): Dom.Content | null {
            const shouldDisplayTooltip =
                anyMultiSelectFieldsTruncated
                || fieldShortDisplayNodes.some((block) => Tooltip.isBeyondClipThreshold(block));
            return shouldDisplayTooltip ? fieldsFullDisplayDiv.innerHTML : null;
        }
    })(fieldsDiv, fieldsFullDisplayDiv);
    return {
        node: fieldsDiv,
        toDestroy: [tooltip],
    };
}

// Returns a row element to be used in a dropdown select.
export function prepRowElement(e: string | Database) {
    const node = Dom.div(
        { class: "table-row action description common-option" },
        Dom.span({ class: "label-node" }, Is.string(e) ? e : e.display()),
    );
    const toDestroy: Util.Destroyable[] = [];
    if (e instanceof Database) {
        const pinnedFieldsDisplay = getPinnedFieldsHtmlElementForDatabase(e);
        if (pinnedFieldsDisplay) {
            Dom.place(pinnedFieldsDisplay, node);
            toDestroy.push(...pinnedFieldsDisplay.toDestroy);
        }
    }
    return {
        node,
        onDestroy: toDestroy,
    };
}

/**
 * @deprecated see {@link DatabaseFieldName}
 */
export class DatabaseFieldNameDisplayer {
    node: HTMLElement;
    toDestroy: Util.Destroyable[];
    constructor(
        private field: DatabaseField,
        showRequired = false,
        showEverlawAccessWarning = true,
    ) {
        const isAccessField = field.isEverlawAccessRestriction();

        let fieldDisplay: HTMLElement;
        this.node = Dom.span((fieldDisplay = Dom.span(field.display())));

        if (!isAccessField) {
            // We never want to ellipsify the access restriction field name. Two
            // CSS classes are necessary to do this due to the DOM structure.
            Dom.addClass(this.node, "database-field__name-wrapper");
            Dom.addClass(fieldDisplay, "ellipsed");
            this.toDestroy = [new Tooltip.MirrorTooltip(fieldDisplay)];
        }

        if (field.isRequired && showRequired) {
            Dom.place(Dom.span({ class: "red-text" }, "*"), this.node);
        }

        if (isAccessField && showEverlawAccessWarning) {
            Dom.addClass(this.node, "database-field-name-everlaw-access-requirement");
            const iconDiv = Dom.create("span", { style: { marginLeft: "6px" } }, this.node);
            const icon = new Icon("info-circle-20");
            Dom.place(icon, iconDiv);
            new Tooltip(icon, DatabaseField.EVERLAW_ACCESS_RESTRICTIONS_INFO);
        }
    }
    destroy() {
        Util.destroy(this.toDestroy);
        this.toDestroy = [];
    }
}

/**
 * Return true if two given field values of a given field are equal.
 */
export function fieldValuesEqual(
    field: DatabaseField,
    initialVal: DatabaseFieldValue.ValueJson | undefined,
    newVal: DatabaseFieldValue.ValueJson | undefined,
): boolean {
    if (!initialVal && !newVal) {
        // if both initial and new values are falsy (null, undefined, empty string, etc.),
        // then they are considered equal because they both represent empty values.
        return true;
    }
    if (Is.array(newVal) && Is.array(initialVal)) {
        // For multiselects, it is possible that options saved in the database
        // have since been deleted from the select. To get around this, we
        // iterate over the available options and ensure that it is in either
        // in both the widget and the database, or in neither.
        const widgetOptIdSet = new Set(newVal);
        const dbOptIdSet = new Set(initialVal);
        return (<DatabaseField.SelectJson>field.json).options
            .map((opt) => opt.id)
            .every((optId) => {
                return (
                    (widgetOptIdSet.has(optId) && dbOptIdSet.has(optId))
                    || (!widgetOptIdSet.has(optId) && !dbOptIdSet.has(optId))
                );
            });
    } else if (Is.object(newVal) && Is.object(initialVal)) {
        return Object.keys(newVal).every((key) => newVal[key] === initialVal[key]);
    }
    return newVal === initialVal;
}

/**
 * Returns true if a database field in an organization is an access field and this field's
 * value is set to be "CJIS" and cannot be changed by the user.
 */
export function isFieldCjisUnmodifiable(field: DatabaseField, org: Organization): boolean {
    return (
        field.type === DatabaseField.Type.SINGLE_SELECT
        && field.isEverlawAccessRestriction()
        && org.cjisFlagAllDbs
    );
}
